<template>
  <div class="logoBg" style="height: 100vh">
    <div class="bindPhoneNumber">
      <div class="phoneNumberLeft">
        <img src="../../assets/img/denglu.png" class="logIcon" />
        <p class="logoDes">
          提供跨境业务AI能力及解决方案，综合运用NLP、CV、多模态、
          ML等技术，解决社媒引流、站内营销、风控安全等业务问题。
          让跨境生意更简单、更高效。
        </p>
        <img src="../../assets/img/logodes.png" class="logDes" />
      </div>
      <div class="phoneNumberRight">
        <div class="bindPhoneText">绑定手机号</div>

        <div class="phoneNumber">
          <input v-model="loginForm.phoneNumber" placeholder="手机号" />
          <img src="../../assets/img/phone.png" class="phoneIcon" />
        </div>
        <div class="codeNumer">
          <input
            v-model="loginForm.textCode"
            class="textCode"
            placeholder="验证码"
          />
          <el-button
            @click="GetVerifyCode()"
            :disabled="getCodeBtnDisable"
            class="code"
            >{{ codeBtnWord }}</el-button
          >
          <img src="../../assets/img/yanzhengma.png" class="yanzhengmaIcon" />
        </div>
        <div class="logTrue" @click="loginBySms">确定</div>
      </div>
    </div>
    <img src="../../assets/img/logo/1.png" class="logo_1" />
    <img src="../../assets/img/logo/3.png" class="logo_3" />
    <img src="../../assets/img/logo/2.png" class="logo_2" />
    <img src="../../assets/img/logo/4.png" class="logo_4" />
    <!-- <div class="phoneNumber">
                    <input 
                        v-model="
                        loginForm.phoneNumber"
                        placeholder="手机号"
                    >
                </div>
                <div class="codeNumer">
                    <input 
                        v-model="
                        loginForm.textCode"
                        class="textCode"
                        placeholder="验证码"
                    >
                    <el-button @click="GetVerifyCode()" :disabled="getCodeBtnDisable" class="code">{{codeBtnWord}}</el-button>
                </div>
                <div class="loginButton" @click="loginBySms()">
                    登录
                </div> -->
  </div>
</template>

<script>
import {
  send,
  phoneCodeVerify,
  wechatLogin,
  phoneToken,
} from "../../api/aidata";
// 公用组件
import "../../assets/css/public.css";

export default {
  data() {
    return {
      loginForm: {
        phoneNumber: "",
        textCode: "",
      },
      codeBtnWord: "获取验证码",
      time: 60,
      getCodeBtnDisable: false,
    };
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // 获取前一页的路由
    next((vm) => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path;
    });
  },
  methods: {
    // 获取验证码，先校验手机号
    GetVerifyCode() {
      // this.timer()
      if (this.loginForm.phoneNumber == "") {
        this.$message.error("手机号不能为空");
        return false;
      } else if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          this.loginForm.phoneNumber
        )
      ) {
        this.$message.error("手机号不正确");
        return false;
      } else if (this.loginForm.phoneNumber.length != 11) {
        this.$message.error("手机号不正确");
        return false;
      } else {
        send({ phone: "+86" + this.loginForm.phoneNumber }).then(({ data }) => {
          if (data.state === "0x0000") {
            this.$message.success("验证码发送成功");
            this.timer();
          } else {
            return this.$message.error(data.message);
          }
        });
      }
    },

    // 验证码准确性认证
    loginBySms() {
      if (this.loginForm.textCode == "") {
        this.$message.error("请填写验证码");
        return false;
      } else if (
        this.loginForm.textCode == "" ||
        this.loginForm.textCode.length < 6
      ) {
        this.$message.error("验证码不能为空并且不能小于6位");
        return false;
      } else {
        // 向后台发送请求
        phoneCodeVerify({
          phone: "+86" + this.loginForm.phoneNumber,
          code: this.loginForm.textCode,
        }).then(({ data }) => {
          // 判断验证码是否有误
          if (!data.data) {
            return this.$message.error("验证码有误");
          }
          if (data.state === "0x0000") {
            // this.$message.success('验证成功')

            if (window.matchMedia("(max-width: 990px)").matches) {
              // console.log("这是一个移动设备。");
              this.phoneToken();
            } else {
              // console.log("这是一个pc端");
              // 绑定手机号登录
              this.bindPhoneNumbe();
            }
          } else {
            this.$message.error(data.message);
          }
        });
      }
    },
    //  据用户手机号获取有效token，过期之后需要重新申请
    phoneToken() {
      // 向后台发送请求
      phoneToken({
        phone: this.loginForm.phoneNumber,
      }).then((res) => {
        if (res.data.state === "0x0000") {
          localStorage.setItem("tokenKey", res.data.data.token);
          // 重定向回到上一页
          this.$router.push({
            path: "/",
          });
        } else {
          return this.$message.error(res.data.message);
        }
      });
    },
    // 绑定手机号登录
    bindPhoneNumbe() {
      // 向后台发送请求
      wechatLogin({
        phone: this.loginForm.phoneNumber,
        openId: this.$route.query.open_id,
        unionId: this.$route.query.union_id,
      }).then(({ data }) => {
        if (data.state === "0x0000") {
          // this.fromPath
          this.$message.success("绑定成功");
          // 重定向回到上一页
          this.$router.push({
            query: {
              union_id: data.data.unionId,
              open_id: data.data.openId,
              status: 1,
            },
            path: "/",
          });
        } else if (data.state == "0x0008") {
          this.$router.push({
            path: "wechat?url=https://aishipgo.com/",
          });
          return this.$message.error(data.message);
        } else if (data.state === "0x0001") {
          return this.$message.error(data.message);
        }
      });
    },

    // 倒计时方法
    timer() {
      let that = this;
      that.time--;
      that.getCodeBtnDisable = true;
      this.codeBtnWord = `${that.time}s `;
      let timer = setInterval(function () {
        if (that.time > 1) {
          that.time--;
          that.codeBtnWord = `${that.time}s `;
        } else {
          clearInterval(timer);
          that.codeBtnWord = "获取验证码";
          that.getCodeBtnDisable = false;
          that.time = 60;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.phoneNumber {
  width: 80%;
  border-bottom: 1px solid #999;
  margin: 117px auto 0 auto;
  position: relative;
}
.phoneNumber input {
  width: 425px;
  height: 40px;
  border: none;
  outline: none;
  margin-left: auto;
  padding-left: 40px;
  background-color: transparent;
  box-sizing: border-box;
}
.codeNumer {
  width: 80%;
  border-bottom: 1px solid #999;
  margin: 90px auto 0 auto;
  display: flex;
  position: relative;
}
.codeNumer input {
  width: 340px;
  height: 40px;
  border: none;
  /* border:1px solid #c5ccdb; */
  border-radius: 5px;
  padding-left: 40px;
  outline: none;
  margin-right: auto;
  background-color: transparent;
}

.code {
  width: 110px;
  border: none;
  color: #250dbb;
  background-color: transparent;
}
.phoneIcon {
  width: 30px;
  position: absolute;
  left: 0;
  top: 5px;
}
.yanzhengmaIcon {
  width: 20px;
  position: absolute;
  left: 7px;
  top: 10px;
}

.logoBg {
  width: 100%;
  /* background: url("../../assets/img/logobg.png") no-repeat; */
  /* background-size: 100%  100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1200px;
}
.bindPhoneNumber {
  width: 1100px;
  /* height: 800px; */
  background: rgba(31, 29, 140, 0.1);
  border-radius: 10px 10px 10px 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  padding-top: 100px;
  z-index: 999;
}
.phoneNumberLeft {
  width: 510px;
  float: left;
}
.logIcon {
  width: 400px;
  display: block;
  margin: auto;
}
.logDes {
  width: 500px;
  display: block;
  margin: auto;
  margin-top: 70px;
}
.phoneNumberRight {
  width: 540px;
  float: right;
}
.logoDes {
  text-align: center;
  font-family: PingFang-SC-Medium, PingFang-SC;
  line-height: 28px;
  color: #000100;
  font-weight: 500;
  font-size: 18px;
  margin-top: 47px;
}
.bindPhoneText {
  font-size: 25px;
  font-family: PingFang-SC-Medium;
  color: #000100;
  text-align: center;
}
.logTrue {
  width: 220px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  background: #edebf3 linear-gradient(#250dbb 0%, #6567e1 100%);
  color: #fff;
  font-size: 16px;
  margin: 100px auto;
}

.logo_1 {
  position: absolute;
  left: 30%;
  bottom: 0;
  width: 20%;
}
.logo_3 {
  position: absolute;
  left: 10%;
  top: 0;
  width: 20%;
}
.logo_2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 25%;
}
.logo_4 {
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
}

@media screen and (max-width: 990px) {
  .phoneNumberLeft {
    display: none;
  }
  .phoneNumberRight {
    width: 100%;
    float: left;
  }
  .bindPhoneNumber {
    width: 100%;
    /* height: 800px; */
    background: rgba(31, 29, 140, 0.1);
    border-radius: 10px 10px 10px 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    padding-top: 100px;
    z-index: 999;
  }
  .logoBg {
    width: 100%;
    /* background: url("../../assets/img/logobg.png") no-repeat; */
    /* background-size: 100%  100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }
}
</style>
